import React, { useEffect, useState } from "react";
import { MemberShow } from '../components/member/show'
import { WPFrontMatter } from "../types/WPFrontMatter";
import { OWNEDMEDIA_WORDPRESS_URL } from "../AppConst";

const fetchMemberPosts = async (pathname, setPosts, setIsLoading, setHasError) => {
  setIsLoading(true)

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/users?slug=${pathname}`)
  .then(response => {return response.json()})
  .then(userData => {
    if(!userData || userData.length == 0) {
      setIsLoading(false)
      return;
    }

    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&author=${userData[0].id}&per_page=3`)
    .then(response => {
      return response.json();
    })
    .then(postsData => {
      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          description: "",
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }
        posts.push(postData);
      })

      setPosts(posts);
      setIsLoading(false)
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });


};

const baseBreadcrumbsData: { text: string; path: string }[] = [
  { text: `MEMBER`, path: `member` },
]

export default function MemberTemplate({ pageContext }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[]>([]);
  const { name, pathname } = pageContext.member;

  useEffect(() => {
    fetchMemberPosts(pathname, setPosts, setIsLoading, setHasError);
  }, [])

  const breadcrumbsData = [
    ...baseBreadcrumbsData,
    ...[{ text: name, path: `/member/${pathname}` }]
  ];

  return (
    <MemberShow breadcrumbsData={breadcrumbsData} member={pageContext.member} members={pageContext.members} posts={posts} isLoading={isLoading} hasError={hasError} />
  )
}