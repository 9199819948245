import React, { useState, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../template/Layout"
import styled from 'styled-components';
import { Link } from 'gatsby';
import Head from "../../head";
import { RESPONSIVE_STYLES } from "../../AppConst";
import { ogpUrl } from "../../utlis/ImageUtil"

import Image from "../common/Image"
import {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"
import { ArticleCard } from "../index/v2/blog/ArticleCard";
import { isSingleByteAlphanumericSymbol } from "../../utlis/check";
import IconTwitter from "../../images/icon_twitter.svg"
import { WPFrontMatter } from "../../types/WPFrontMatter";
import { LoadingAPI } from "../common/LoadingAPI";
import { ReadMore } from "../common/ReadMore";
import { ConnectionFailedText } from "../common/ConnectionFailedText";

const MaxFirstViewCommentCount = 3;

const Node = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 80px;
`

type Props = {
  breadcrumbsData: BreadcrumbsData["values"],
  member: any,
  members: any,
  posts: WPFrontMatter[],
  isLoading: boolean,
  hasError: boolean,
}

const HeadWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  height: 720px;
  margin: auto;
  gap: 80px;
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    height: 480px;
    padding: 0 32px;
    gap: 40px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    flex-direction: column;
    height: auto;
    padding: 0 16px;
  }
`

const HeadProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    order: 2;
  }
`

const HeadImageWrapper = styled.div`
  display: flex;
  width: 520px;
  height: 720px;
  background: linear-gradient(to top, white 0%, white 15%, #f4f4f4 15%, #f4f4f4 80%, white 80%, white 100%);
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 320px;
    height: 480px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    order: 1;
    margin: auto;
  }
  .gatsby-image-wrapper {
    aspect-ratio: 1/1;
    @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
      height: 480px;
    }
    img {
      height: auto !important;
    }
  }
`

const Position = styled.span`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.1rem;
  letter-spacing: 0.7px;
  color: var(--gray-50, #909090);
`

const Name = styled.h1`
  position: relative;
  width: 100%;
  margin: 16px auto 0;
  padding: 0 0 16px;
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 7.2rem;
  letter-spacing: 2.4px;
  text-transform: capitalize;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 0 auto 0;
    padding: 0;
    font-size: 3.2rem;
    line-height: 4.8rem;
    letter-spacing: 1.6px;
  }
`

const DescriptionWrapper = styled.div`
  width: 100%;
  margin: 24px auto 0;
`

const Descriptions = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4rem;
  letter-spacing: 1.4px;
  color: #222222;
  list-style: none;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.0rem;
    letter-spacing: 1.0px;
    gap: 0;
  }
`

const Description = styled.li`
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--gray-100, #222);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: 0.6px;
`

const BorderLine = styled.div`
  width: 80px;
  height: 1px;
  margin-block: 32px;
  background: #222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-block: 24px;
  }
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {

  }
`

const Message = styled.p`
  width: 100%;
  margin: 0;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.8rem;
  letter-spacing: 0.7px;
  text-align: left;
  color: #222222;
`

const GraphWrapper = styled.div<{isTop: boolean}>`
  width: 100%;
  max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  margin: ${props => props.isTop ? 80 : 160}px auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 80px auto;
    padding: 0 32px;
    box-sizing: border-box;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    padding: 0 16px;
  }
`

const GraphTitle = styled.h2`
  position: relative;
  width: 100%;
  margin: 0 0 40px;
  padding: 0;
  color: var(--gray-100, #222);
  font-size: 2.4rem;
  font-weight: 700;
  font-style: normal;
  line-height: 3.6rem;
  letter-spacing: 1.2px;
  text-transform: capitalize;
  text-align: left;
  color: #222222;
  &[data-is-jp='true'] {
    font-family: inherit;
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: 2.4px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 0 0 32px;
    color: var(--gray-100, #222);
    font-size: 2rem;
    line-height: 3rem;
    letter-spacing: 1px;
  }
`

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px 80px;
  width: 100%;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column;
  }
`

const SkillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 40px);
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: none;
  }
`

const SkillNameWrapper = styled.div`
  display: flex;
  margin-bottom: 13px;
`

const SkillName = styled.h3`
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.8px;
  color: #222222;
  text-align: left;
  word-break: break-word;
`

const SkillGraphParam = styled.span`
  display: inline-block;
  margin-left: auto;
  box-sizing: border-box;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.8px;
  color: #222222;
  text-align: right;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 40px;
    margin-left: auto;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.4rem;
  }
`

const SkillGraphWrapper = styled.div`
  width: 500px;
  background-color: #ffffff;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: none;
  }
`

const SkillGraphProgress = styled.div`
  position: relative;
  width: 500px;
  max-width: 500px;
  margin: 0;
  height: 1px;
  background-color: #222;
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    width: calc((100%) * (${({value}) => value} / 100));
    max-width: 500px;
    height: 10px;
    background-color: #222;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: none;
    &::after {
      max-width: 100%;
    }
  }
`

const PersonalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px 0;
  width: 100%;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 32px 0;
  }
`

const PersonalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column;
  }
`

const PersonalName = styled.h3`
  width: 160px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: 0.8px;
  color: #222222;
  text-align: left;
  word-break: break-word;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    margin: 0 0 16px;
  }
`

const PersonalGraphNamePCWrapper = styled.div`
  display: block;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: none;
  }
`

const PersonalGraphNameSpWrapper = styled.div`
  width: 100%;
  display: none;
  margin: 25px 0;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
  }
`

const PersonalGraphNameWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 200px;
  padding: 12px 0;
  background-color: #f4f4f4;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 160px;
  }
`

const PersonalGraphName = styled.span`
  display: inline-block;
  width: 100%;
  color: var(--gray-100, #222);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.1rem;
  letter-spacing: 0.7px;
  color: #222222;
  text-align: center;
`

const PersonalGraph = styled.div`
  position: relative;
  width: 560px;
  height: 1px;
  margin: 0 24px;
  background-color: #222;
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0 auto calc(${props => props.value}% - 12px);
    background-color: #222;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 0;
    width: 100%;
  }
`

const CansWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column;
    gap: 32px;
  }
`

const CanWrapper = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--gray-5, #F4F4F4);
  width: calc(50% - 20px);
  box-sizing: border-box;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: none;
    min-height: 46px;
  }
`

const Can = styled.p`
  color: var(--gray-100, #222);
  display: block;
  margin: 0;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: 0.8px;
  font-stretch: normal;
  text-align: center;
  color: #222222;
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  }
`

const CommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 32px;
  }
`

const CommentWrapper = styled.div`
  display: ${ ({isDisplayNone}) => isDisplayNone ? 'none' : 'flex' };
  gap: 16px;
`

const CommentAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CommentAvatar = styled(Link)`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  .gatsby-image-wrapper {
    border-radius: 50%;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    width: 48px;
    height: 48px;
  }
`

const CommentName = styled.span`
  display: inline-block;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.8px;
  text-align: left;
  color: #222222;
`

const CommentDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
`

const CommentDescription = styled.p`
  width: 100%;
  margin: 4px 0 0;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.8rem;
  letter-spacing: 0.7px;
  color: #222222;
`

const ReadmoreBtnWrapper = styled.div`
  margin-top: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: 32px;
  }
`

const BtnCommentWrapper = styled.div<{isOpenedComment: Boolean}>`
  width: 100%;
  display: ${({isOpenedComment}) => isOpenedComment ? 'none' : 'flex'};
`

const BtnComment = styled.a`
  display: block;
  margin: auto;
  text-align: center;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1.6px;
  line-height: 2.4rem;
  color: #222222;
  &:hover {
    color: #61B1C1;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.2rem;
    letter-spacing: 1.2px;
  }
`

const MemberBlogsLink = styled(Link)`
  display: block;
  margin: 48px auto 0;
  text-align: center;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1.6px;
  line-height: 2.4rem;
  color: #222222;
  &:hover {
    color: #61B1C1;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.2rem;
    letter-spacing: 1.2px;
  }
`

const BlogContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #E9E9E9;
`

const ArticleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 24px;
  }
  &::after {
    display: block;
    content:"";
    width: 30%;
  }
`

const CardDescription = styled.p`
  display: inline-block;
  width: 100%;
  margin: 2rem 0;
  font-family: a-otf-gothic-bbb-pr6n, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.6px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
  }
`

const Skill = ({skill, color}) => {
  return (
    <SkillWrapper>
      <SkillNameWrapper>
        <SkillName data-is-eng={isSingleByteAlphanumericSymbol(skill.name)}>{skill.name}</SkillName>
        <SkillGraphParam>{skill.value === 100 ? skill.value : (`00` + skill.value).slice(-2)}%</SkillGraphParam>
      </SkillNameWrapper>
      <SkillGraphWrapper>
        <SkillGraphProgress color={color} value={skill.value} />
      </SkillGraphWrapper>
    </SkillWrapper>
  )
}

const Personal = ({color, personal}) => {
  return (
    <PersonalWrapper>
      <PersonalName>{personal.name}</PersonalName>
      <PersonalGraphNamePCWrapper>
        <PersonalGraphNameWrapper>
          <PersonalGraphName dangerouslySetInnerHTML={{ __html: personal.left }} />
        </PersonalGraphNameWrapper>
      </PersonalGraphNamePCWrapper>
      <PersonalGraph color={color} value={personal.value} />
      <PersonalGraphNamePCWrapper>
        <PersonalGraphNameWrapper>
          <PersonalGraphName dangerouslySetInnerHTML={{ __html: personal.right }} />
        </PersonalGraphNameWrapper>
      </PersonalGraphNamePCWrapper>

      <PersonalGraphNameSpWrapper>
        <PersonalGraphNameWrapper>
          <PersonalGraphName dangerouslySetInnerHTML={{ __html: personal.left }} />
        </PersonalGraphNameWrapper>
        <PersonalGraphNameWrapper>
          <PersonalGraphName dangerouslySetInnerHTML={{ __html: personal.right }} />
        </PersonalGraphNameWrapper>
      </PersonalGraphNameSpWrapper>
    </PersonalWrapper>
  )
}

const Comment = ({comment, member, index, isOpenedComment}) => {
  return (
    <CommentWrapper isDisplayNone={!isOpenedComment && index >= MaxFirstViewCommentCount}>
      <CommentAvatarWrapper>
        <CommentAvatar to={`/member/${member.pathname}`}>
          <Image filename={member.filename} />
        </CommentAvatar>
      </CommentAvatarWrapper>
      <CommentDescriptionWrapper>
        <CommentName>{comment.name}</CommentName>
        <CommentDescription dangerouslySetInnerHTML={{ __html: comment.comment}} />
      </CommentDescriptionWrapper>
    </CommentWrapper>
  )
}

const Section = ({title, dataIsJp=false, children, isTop=false}) => {
  return (
    <GraphWrapper isTop={isTop}>
      <GraphTitle data-is-jp={dataIsJp}>{title}</GraphTitle>
      {children}
    </GraphWrapper>
  )
}

const SNS: React.VFC<{member: any}> = ({member}) => {
  const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      gap: 8px;
    }
  `
  const IconWrapper = styled.a`
  `
  const Icon = styled.img`
    width: 48px;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      width: 24px;
    }
  `

  if (!member.twitter_url) {
    return null;
  }

  return (
    <Wrapper>
      <IconWrapper href={member.twitter_url} target="_blank" rel="noopener noreferrer">
        <Icon src={IconTwitter} alt={"Twitter"} />
      </IconWrapper>
    </Wrapper>
  )
}

export const MemberShow: React.VFC<Props> = ({
  breadcrumbsData, member, members, posts, isLoading, hasError
}) => {
  const title = `${member.name}のメンバーページ | ネクストライブ株式会社`;
  const description = `${member.name}のメンバーページです。`;
  const [isOpenedComment, setIsOpenedComment] = useState(false);

  const displayMenber = members.filter(m => m.displayed_member === true);
  const memberComents = member.comments.filter(comment => {
    const found = displayMenber.find(m => m.name === comment.name);
    if(!found) return;

    return comment
  });

  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 540) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const ogpImage = data.images.edges.find(n => {
    return n.node.relativePath.includes(member.filename);
  });

  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title={title}
        description={description}
        img={ogpUrl(process.env.NODE_ENV, ogpImage.node.childImageSharp.fluid)}
      />
      <Node>
        <HeadWrapper>
          <HeadProfileWrapper>
            <SNS member={member} />
            <Name color={member.color}>{member.name}</Name>
            <Position>{member.position}</Position>
            <DescriptionWrapper>
              <Descriptions>
                {
                  member.teams.map((team) => <Description>{team}</Description>)
                }
              </Descriptions>
            </DescriptionWrapper>
            <BorderLine />
            {
              member.messages.length > 0 &&
              <MessageWrapper>
                {
                  member.messages.map((message) => <Message>{message}</Message>)
                }
              </MessageWrapper>
            }
          </HeadProfileWrapper>
          <HeadImageWrapper>
            <Image filename={member.member_show_filename} />
          </HeadImageWrapper>
        </HeadWrapper>
        {
          false && member.skills.length > 0 &&
          <Section title={`SKILLS`}>
            <Skills>
              {
                member.skills.map((skill) => <Skill color={member.color} skill={skill} />)
              }
            </Skills>
          </Section>
        }
        {
          member.personals.length > 0 &&
          <Section title={`PERSONAL`}>
            <PersonalsWrapper>
              {
                member.personals.map((personal) => <Personal color={member.color} personal={personal} />)
              }
            </PersonalsWrapper>
          </Section>
        }
        {
          member.cans.length > 0 &&
          <Section title={`CAN`}>
            <CansWrapper>
            {
              member.cans.map((can) => <CanWrapper><Can>{can}</Can></CanWrapper>)
            }
            </CansWrapper>
          </Section>
        }
        {
          false && member.comments.length > 0 &&
          <Section title={`COMMENTS`}>
            <CommentsWrapper>
              {
                memberComents.map((comment, i) => {
                  const member = members.find(member => member.name === comment.name);
                  return (<Comment comment={comment} member={member} index={i} isOpenedComment={isOpenedComment} />)
                })
              }
              {
                member.comments.length >= MaxFirstViewCommentCount &&
                  <BtnCommentWrapper isOpenedComment={isOpenedComment}>
                    <BtnComment href={"javascript:void(0)"} onClick={() => {setIsOpenedComment(true)}}>
                      もっと見る▼
                    </BtnComment>
                  </BtnCommentWrapper>
              }
            </CommentsWrapper>
          </Section>
        }
        <BlogContainer>
          <Section dataIsJp={true} title={`${member.name}の記事`}>
            { hasError ? <ConnectionFailedText /> :
                isLoading ? <LoadingAPI /> :
                  <>
                    <ArticleWrapper>
                    {
                      posts.length === 0 && <CardDescription>まだ記事はありません。</CardDescription>
                    }
                    {
                      posts.length > 0 && posts.map((post, i) => {
                        return (
                          <ArticleCard
                            key={i}
                            frontMatter={post}
                            member={member}
                          />
                        )
                      })
                    }
                    </ArticleWrapper>
                    {
                      posts.length > 0 &&
                        <ReadmoreBtnWrapper>
                          <ReadMore url={`/blog/${member.pathname}`} darkMode={true} text='ブログをもっと見る' />
                        </ReadmoreBtnWrapper>
                    }
                  </>
            }
          </Section>
        </BlogContainer>
      </Node>
    </Layout>
  )
}
